import React, { useState } from 'react';
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import PopuVideo from "../components/PopuVideo"
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Layout from "../components/layout"
import Button from "../components/styles/Button"

export const pageQuery = graphql`
 query {
    allStrapiAboutUs {
        edges {
            node {
                seo {
                    metaTitle
                    metaDescription
                    shareImage {
                    url
                    }
                },
                topBlock {
                    button {
                        name
                        url
                      }
                      text {
                        info
                        title
                      }
                },
                mission {
                    text {
                      info
                      title
                    }
                    video {
                      link
                      background {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(width: 3840, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                          }
                        }
                      }
                    }
                  },
                  products {
                    info
                    title
                    link
                    image {
                        localFile {
                            childImageSharp {
                              gatsbyImageData(width: 1380, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                            }
                          }
                    }
                  },
                  about_lists {
                      title
                      url
                      description
                      recommand
                      seo {
                            shareImage {
                                localFile {
                                    childImageSharp {
                                    gatsbyImageData(width: 945, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                                    }
                                }
                            }
                        }
                  }
            }
        }
    }
 }
`

export default function About({location,data}) {
    const [headerColor,setHeaderColor] = useState("bg-white text-black")
    const node = data.allStrapiAboutUs.edges[0].node
    return (
        <Layout bgColor={'bg-white'} footer={true} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="container mx-auto pt-[200px] pb-[100px] flex items-start justify-between xl:flex-col sm:pt-24 sm:pb-20">
                <h1 className="text-[40px] leading-snug xl:mb-8">{node.topBlock.text.title}</h1>
                <div className="w-[944px] xl:w-full">
                    <p className="text-[40px] leading-snug pb-10 sm:text-3xl">{node.topBlock.text.info}</p>
                    <Button variant="black" href={node.topBlock.button.url}>{node.topBlock.button.name}</Button>
                </div>
            </div>
           
            <div className="max-w-[1672px] w-full px-4 mx-auto h-[920px] relative xl:h-[580px] sm:h-[376px]">
                <PopuVideo video={node.mission.video.link} bg={node.mission.video.background.localFile}/>
            </div>
            <div className="container mx-auto pt-10 pb-[100px] flex items-start justify-between xl:flex-col">
                <h2 className="text-[40px] leading-snug xl:mb-8">{node.mission.text.title}</h2>
                <p className="w-[944px] xl:w-full">{node.mission.text.info}</p>
            </div>
            <div className="container mx-auto grid grid-cols-2 gap-x-[60px] pb-[100px] xl:grid-cols-1 xl:gap-y-20">
                {
                    node.products.map((n,i)=>{
                        return (
                            <div key={i} className="bg-white">
                                <figure className="h-[690px] xl:h-auto overflow-hidden"><GatsbyImage className="h-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(n.image.localFile)} alt="banner"/></figure>
                                <h2 className="text-[40px] py-5 leading-snug">{n.title}</h2>
                                <p className="pb-10">{n.info}</p>
                                <Button variant="black" href={n.link}>了解更多</Button>
                            </div>
                        )
                    })
                }
                
            </div>
            
            <div className="container mx-auto pb-[140px] grid grid-cols-3 gap-x-[45px]  xl:grid-cols-2 sm:grid-cols-1 xl:gap-y-20 sm:pb-20">
                {
                    node.about_lists.map((n,i)=>{
                        if(!n.recommand){
                            return (
                                <div key={i} className="bg-white">
                                    <figure className="h-[330px] xl:h-auto overflow-hidden"><GatsbyImage className="h-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(n.seo.shareImage.localFile)} alt="banner"/></figure>
                                    <h2 className="text-[40px] py-5 leading-snug xl:mb-8">{n.title}</h2>
                                    <p className="pb-10">{n.description}</p>
                                    <Button variant="black" href={n.url}>了解更多</Button>
                                </div>
                            )
                        }else{
                            return (
                                <div className="col-span-3 pb-[100px] xl:col-span-2 sm:col-span-1 sm:pb-0">
                                    <figure className="w-full relative overflow-hidden"><GatsbyImage className="w-full h-full bg-light transform transition-all duration-1000 ease-linear hover:scale-110" image={getImage(n.seo.shareImage.localFile)} alt="banner"/></figure>
                                    <div className="container mx-auto pt-10 flex items-start justify-between xl:flex-col">
                                        <h2 className="text-[40px] leading-snug xl:mb-8">{n.title}</h2>
                                        <div className="w-[944px] xl:w-full">
                                            <p className="pb-10">{n.description}</p>
                                            <Button variant="black" href={n.url}>了解更多</Button>
                                        </div>
                                    </div>
                                </div>)
                        }
                        
                    })
                }
                
                
            </div>
        </Layout>
    )
}

